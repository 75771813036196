import firebase from 'firebase';
import "@react-native-firebase/auth";
//DEV ENV
// var firebaseConfig = {
//     apiKey: "AIzaSyDSF1uogD8-mKE3n_MPxizuDt0CYRcnbA0",
//     authDomain: "shippie-dev.firebaseapp.com",
//     projectId: "shippie-dev",
//     storageBucket: "shippie-dev.appspot.com",
//     messagingSenderId: "95922501679",
//     appId: "1:95922501679:web:a8bcca89782feb95b2eeda",
//     measurementId: "G-GX14MSYH81"
// };

//PROD ENV
const firebaseConfig = {
  apiKey: "AIzaSyAbjIfNfabZbjCOwbSCKYO8tYjCX1ZQ0K8",
  authDomain: "shippie-prod.firebaseapp.com",
  databaseURL: "https://shippie-prod-default-rtdb.firebaseio.com",
  projectId: "shippie-prod",
  storageBucket: "shippie-prod.appspot.com",
  messagingSenderId: "1049969398720",
  appId: "1:1049969398720:web:1324dc1eff893139742e25",
  measurementId: "G-TQN81EJWJW"
};

firebase.initializeApp(firebaseConfig);

export const firebaseApp = firebase;
