import { SecureStore } from "expo";
import { Platform } from "react-native";


export async function setLocalStorage(key: string, value: any) {
  if (Platform.OS === `web`) {
    localStorage.setItem(key, value);
  } else {
    await SecureStore.setItemAsync(key, value);
  }
}

export async function getLocalStorage(key: string) {
  if (Platform.OS === `web`) {
    return localStorage.getItem(key);
  } else {
    return await SecureStore.getItemAsync(key);
  }
}

export async function deleteLocalStorage(key: string) {
  if (Platform.OS === `web`) {
    localStorage.removeItem(key);
  } else {
    await SecureStore.deleteItemAsync(key);
  }
}
