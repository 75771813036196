import React, { useState, useEffect } from 'react';
import {Text, View, StyleSheet, Button, Modal, Platform, StatusBar, Image, Alert, Dimensions, Switch, TouchableOpacity, ActivityIndicator, TouchableNativeFeedback, Vibration, ScrollView } from 'react-native';
import {Picker} from '@react-native-community/picker';
import { BarCodeScanner } from 'expo-barcode-scanner';
import { Audio } from 'expo-av';

import { OrderStatusEnum, PackageSizeEnum, ScannerModeEnum, ShippieOrder } from '../../types/OrderTypes';
import { updateOrderStatusByScanner, getWorkerByDriver, updatePackageSizeByDriver, getMerchantsPickedUpOrders, getScannedOrders, updatePackageMultiplier, sendAwaitingPickupConfirmation } from '../api/cloudfunctions/orders';
import {firebaseApp} from '../../firebase/config';
import GestureRecognizer from 'react-native-swipe-gestures';
import QRScanner from './QRScanner';
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
} from 'react-native-popup-menu';
import Toast from 'react-native-toast-message';
import { Camera } from 'expo-camera';


let scanTimer: NodeJS.Timeout;

export default function Scanner({logOut}) {
  const [hasPermission, setHasPermission] = useState<boolean>(false);
  const [worker, setWorker] = useState(null);
  
  const [scanning, setScanning] = useState<boolean>(false);
  const [scannerOn, setScannerOn] = useState<boolean>(false);

  const [scannedList, setScannedList] = useState();

  const [loadingWorker, setLoadingWorker] = useState<boolean>(false);
  const [loadingApp, setLoadingApp] = useState<boolean>(false);
  const [sendingEmail, setSendingEmail] = useState<boolean>(false);

  const [warehouseSelectModalVisible, setWarehouseSelectModalVisible] = useState<boolean>(false);

  const [orderModalVisible, setOrderModalVisible] = useState<boolean>(false);
  const [thisOrder, setThisOrder] = useState<ShippieOrder>();
  const [orderCount, setOrderCount] = useState(null);

  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [mode, setMode] = useState(ScannerModeEnum.DRIVER);

  const [allWarehouses, setAllWarehouses] = useState([]);
  const [user, setUser] = useState();
  const [saving, setSaving] = useState(false);
  const [lastScanned, setLastScanned] = useState<string>();

  useEffect(() => {
    getUser();
    getAllWarehouses();
    (async () => {
      if (Platform.OS === `web`) {
        const { status } = await Camera.requestPermissionsAsync();
        setHasPermission(status === 'granted');
      } else {
        const { status } = await BarCodeScanner.requestPermissionsAsync();
        setHasPermission(status === 'granted');
      }
    })();
  }, []);

  const getUser = async () => {
    setLoadingApp(true);
    try {
      const user = firebaseApp.auth().currentUser;
      const db = firebaseApp.firestore();
      if (user) {
        const doc = await db.collection('Scanners').doc(user.phoneNumber).get();
        const data = doc.data();
        setUser(data);
        if (data?.type === ScannerModeEnum.MERCHANT) {
          setMode(ScannerModeEnum.MERCHANT);
          getScannedCounts();
        }
      }
    } catch (err) {
      console.log(err);
      alert(err.message);
    } finally {
      setLoadingApp(false);
    }
  }

  const getAllWarehouses = async () => {
    try {
      const db = firebaseApp.firestore();
      const querySnapshot = await db.collection('Warehouses').get();
      const warehouses = querySnapshot.docs.map(doc => doc.data());
      setAllWarehouses(warehouses);
    } catch (err) {
      alert(err);
    }
  }

  const getOrderCount = async (orderId: string) => {
    try {
      const {orderCount} = await getMerchantsPickedUpOrders(orderId);
      
      if (orderCount) {
        setOrderCount(orderCount);
      }
    }
    catch (err) {
      throw err;
    }
  }

  const getOrder = async (orderId: string) => {
    try {
      const {order} = await updateOrderStatusByScanner(orderId, mode, selectedWarehouse?.id, true, false);
      if (order) {
        setThisOrder(order);
      }
      return order;
    }
    catch (err) {
      throw err;
    }
  }

  const getWorker = async (orderId: string) => {
    setLoadingWorker(true);
    try {
      const {worker} = await getWorkerByDriver(orderId);  
      if (worker) {
        setWorker(worker);
      }
    }
    catch (err) {
      throw err;
    } finally {
      setLoadingWorker(false);
    }
  }

  const closeModal = async () => {
    setWorker(null);
    setThisOrder(null);
    setOrderCount(null);
    setOrderModalVisible(false);
    setScannerOn(true);
  }

  const retryOrder = async () => {
    Alert.alert(
      "Retry Order",
      "Are you sure you want to set this order to Retry?",
      [
        {
          text: "Cancel",
          style: "cancel"
        },
        { text: "OK", onPress: setOrderStatusToRetry }
      ]
    );
  }

  const setOrderStatusToRetry = async() => {
    try {
      const {order} = await updateOrderStatusByScanner(thisOrder.id, mode, selectedWarehouse.id, true, true);
      if (order) {
        setThisOrder(order);
        Alert.alert('', `Order ${order.id} has been set to Retry`);
      }
    }
    catch (err) {
      Alert.alert('', err.message);
    }
  }

  const getScannedCounts = async () => {
    try {
      if (user && user.merchantId) {
        const {ordersList} = await getScannedOrders(`merchant`, user.merchantId);
        setScannedList(ordersList);
      }
    } catch (err) {
      console.log(err);
    }
  }

  const handleBarCodeScanned = async ({ data }: {data: string}) => {
    console.log("handleBarCodeScanned")
    setScanning(false);
    setLastScanned(data);
    const orderId = data;
    if (!orderId) {return Alert.alert('', `Invalid QR code`)}
    clearTimeout(scanTimer);

    try {
      if (lastScanned !== orderId) {
        playSound();
        Vibration.vibrate(500);
        if (mode === ScannerModeEnum.WAREHOUSE) {
          setOrderModalVisible(true);
        }
        const order = await getOrder(orderId);
        Toast.show({
          topOffset: 30,
          type: `success`,
          text1: `Scanned #${order.orderNumber}`,
          autoHide: true,
          visibilityTime: 1000,
          position: `top`,
        });
        
        if (mode === ScannerModeEnum.MERCHANT) {
          getScannedCounts();
        }
        else if (mode === ScannerModeEnum.WAREHOUSE) {
          getWorker(orderId);
          getOrderCount(orderId);
          setScannerOn(false);
        } else if (mode === ScannerModeEnum.DRIVER) {
          getOrderCount(orderId);
        }
      } else {
        Alert.alert('', `Scanned recently`);
      }
    } catch (err) {
      console.log(err)
      Alert.alert('', err.message);
      closeModal();
    } finally {
      setTimeout(() => {
        setScanning(true);
      }, 1000);
      scanTimer = setTimeout(() => {
        setScanning(false);
        setScannerOn(false);
      }, 60000);
    }
  };

  const startScan = () => {
    setScannerOn(true);
    setScanning(true);
    setWorker(null);
  }

  const cancelScan = () => {
    setScannerOn(false);
    setScanning(false);
    setLastScanned(undefined);
  }

  if (hasPermission === null) {
    return <View style={styles.loadingContainer}><Text>Requesting for camera permission</Text></View>;
  }
  if (hasPermission === false) {
    return <View style={styles.loadingContainer}><Text>No access to camera</Text></View>;
  }

  const updatePackageSize = async (orderId: string, size: string) => {
    setSaving(true);
    try {
      const {updatedOrder} = await updatePackageSizeByDriver(orderId, size);

      if (updatedOrder) {
        setThisOrder(updatedOrder);
      }
    } catch (err) {
      Alert.alert(`error`, err.message);
    } finally {
      setSaving(false);
    }
  }

  const updateMultiplier = async (orderId: string, multiplier: number) => {
    setSaving(true);
    try {
      const {updatedOrder} = await updatePackageMultiplier(orderId, multiplier);

      if (updatedOrder) {
        setThisOrder(updatedOrder);
      }
    } catch (err) {
      Alert.alert(`error`, err.message);
    } finally {
      setSaving(false);
    }
  }

  const selectWarehouse = (warehouse: any) => {
    setSelectedWarehouse(warehouse);
    setWarehouseSelectModalVisible(false);
  }
  
  async function playSound() {
    const { sound } = await Audio.Sound.createAsync(
       require('./assets/scanned.mp3')
    );

    await sound.playAsync();
    // Don't forget to unload the sound from memory
    // when you are done using the Sound object
    // await sound.unloadAsync();
  }

  const renderMode = () => {
    if (mode === ScannerModeEnum.MERCHANT) {
      return (
        <View style={styles.transparentView}>
          <View style={styles.col}>
            <Text style={[styles.whiteText, styles.bold]}>Merchant Scanner</Text>
            <Text style={styles.whiteText}>{`Scanned: ${scannedList?.length || 0}`}</Text>
          </View>
          <View style={styles.col}>
            <Text style={styles.whiteText}>{user?.merchantName}</Text>
            <Text style={styles.whiteText}>{user?.name}</Text>
          </View>
        </View>
      )
    } else {
      return (
        <View>
          <View style={styles.modeView}>
            <View style={styles.modeSwitcher}>
              <Text style={styles.modeText}>{mode.toUpperCase()}</Text>
              <Switch
                trackColor={{ false: "#767577", true: "#81b0ff" }}
                thumbColor={mode === ScannerModeEnum.DRIVER ? "green" : "orange"}
                ios_backgroundColor="#3e3e3e"
                onValueChange={() => {
                  if (mode === ScannerModeEnum.DRIVER) {
                    setWarehouseSelectModalVisible(true);
                    setMode(ScannerModeEnum.WAREHOUSE);
                  }
                  else if (mode === ScannerModeEnum.WAREHOUSE) {
                    setSelectedWarehouse(null);
                    setMode(ScannerModeEnum.DRIVER)
                  }
                }}
                value={mode === ScannerModeEnum.DRIVER}
              />
              <Text style={styles.modeText}>{selectedWarehouse?.name?.toUpperCase()}</Text>
            </View>
          </View>
        </View>
      )
    }
  }

  const getStatusColor = () => {
    if (thisOrder?.status === OrderStatusEnum.PICKEDUP) {
      return "orange";
    }
    else if (thisOrder?.status === OrderStatusEnum.RECEIVED) {
      return "purple";
    }
    else if (thisOrder?.status === OrderStatusEnum.RETRY || thisOrder?.status === OrderStatusEnum.FAILED) {
      return "red";
    }
  }

  const renderStatus = () => {
    const color = getStatusColor();
    return (
      <Text style={{
        fontWeight: 'bold',
        fontSize: 15,
        textAlign: 'center',
        color,
      }}>{thisOrder?.status.toUpperCase()}</Text>
    )
  }

  const sendPickingUpEmail = async () => {
    try {
      setSendingEmail(true);
      await sendAwaitingPickupConfirmation(user?.merchantId, mode);
    } catch (err) {
      console.log(err);
    } finally {
      setSendingEmail(false);
    }
  }

  function renderSelectWarehouse() {
    if (warehouseSelectModalVisible) {
      return (
        // <GestureRecognizer
        //   style={{flex: 1}}
        //   onSwipeDown={ () => {
        //     setMode(ScannerModeEnum.DRIVER);
        //     setWarehouseSelectModalVisible(false)}
        //   }
        // >
          <Modal
            animationType="slide"
            transparent={false}
            visible={warehouseSelectModalVisible}
            onRequestClose={() => setWarehouseSelectModalVisible(false)}
          > 
            <View style={{margin: 50}}>
              <Text>Please Select Warehouse:</Text>
              <Picker
                selectedValue={selectedWarehouse}
                onValueChange={(warehouse) => selectWarehouse(warehouse)}
              > 
                <Picker.Item label={''} value={''} />
                {
                  allWarehouses.map((warehouse) => {
                    return (
                      <Picker.Item label={warehouse.name} value={warehouse} />
                    )
                  })
                }
              </Picker>
              
              {/* <Button onPress={() => setWarehouseSelectModalVisible(false)} title='Close'></Button> */}
            </View>
          </Modal>
        // </GestureRecognizer>
      )
    }
  }

  function renderReceivedView() {
    if (orderModalVisible) {
      return (
        <GestureRecognizer
          style={{flex: 1}}
          onSwipeDown={closeModal}
        >
          <Modal
            animationType="slide"
            transparent={false}
            visible={orderModalVisible}
            onRequestClose={() => setOrderModalVisible(false)}
          >
            <ScrollView>
              <View style={styles.modalHeader}>
                <Text style={styles.mainTitle}>Pickedup: {orderCount}</Text>
                {
                  mode === ScannerModeEnum.WAREHOUSE
                  ?
                  <Button color='red' onPress={retryOrder} title='Retry'></Button>
                  :
                  null
                }
              </View>
              {
                thisOrder
                ?
                <View style={styles.orderInfo}>
                  <Text style={styles.mainTitle}>ORDER</Text>
                  {renderStatus()}
                  <Text style={styles.orderId}>{thisOrder?.id}</Text>

                  <View style={styles.data}>
                    <View style={styles.row}>
                      <View>
                        <Text style={styles.title}>Name: </Text>
                        <Text style={styles.text}>{thisOrder?.fullName}</Text>
                        <Text style={styles.title}>Phone: </Text>
                        <Text style={styles.text}>{thisOrder?.phoneNumber}</Text>
                      </View>
                      <View>
                        <Text style={styles.title}>Address 1: </Text>
                        <Text style={styles.text}>{thisOrder?.address1}</Text>
                        <Text style={styles.title}>Address 2: </Text>
                        <Text style={styles.text}>{thisOrder?.address2}</Text> 
                      </View>
                    </View>
                  </View>
                  {
                    saving
                    ?
                    <ActivityIndicator />
                    :
                    <View style={styles.row}>
                      <View>
                        <Text style={{fontSize: 12, fontWeight: 'bold', color: 'black'}}>Package Multiplier: </Text>
                        <View style={{backgroundColor: 'white', borderRadius: 50}}>
                          <Picker
                            selectedValue={thisOrder?.packageMultiplier || 1}
                            onValueChange={(itemValue) => updateMultiplier(thisOrder?.id, itemValue)}
                          > 
                            {
                              [1, 2, 3, 4, 5, 6].map((value) => {
                                return (
                                  <Picker.Item label={value.toString()} value={value} />
                                )
                              })
                            }
                          </Picker>
                        </View>
                      </View>
                      <View>
                        <Text style={{fontSize: 12, fontWeight: 'bold', color: 'black'}}>Package Size: </Text>
                        <View style={{backgroundColor: 'white', borderRadius: 50}}>
                          <Picker
                            selectedValue={thisOrder?.packageSize || PackageSizeEnum.SMALL}
                            onValueChange={(itemValue) => updatePackageSize(thisOrder?.id, itemValue)}
                          > 
                            {
                              Object.keys(PackageSizeEnum).map((key) => {
                                const value = PackageSizeEnum[key];
                                return (
                                  <Picker.Item label={value} value={value} />
                                )
                              })
                            }
                          </Picker>
                        </View>
                      </View>
                    </View>
                  }
                </View> 
                :
                <ActivityIndicator size="large" color="#3399FF" />
              }
              {
                worker
                ?
                <View style={styles.driverInfo}>
                  <Text style={styles.mainTitle}>DRIVER</Text>
                  <View style={styles.data}>
                    <View style={styles.row}>
                      <View>
                        <Text style={styles.important}>{worker?.name}</Text>
                        <Text></Text>
                        <Text style={styles.title}>Vehicle color: </Text>
                        <Text style={styles.text}>{worker?.vehicle?.color}</Text>
                        <Text style={styles.title}>Phone: </Text>
                        <Text style={styles.text}>{worker?.phone}</Text>
                      </View>
                      <View>
                        <Text style={styles.important}>#{worker?.tasks?.indexOf(thisOrder?.taskId) + 1}</Text>
                        <Text></Text>
                        <Text style={styles.title}>License Plate: </Text>
                        <Text style={styles.text}>{worker?.vehicle?.licensePlate}</Text>
                        <Text style={styles.title}>Description: </Text>
                        <Text style={styles.text}>{worker?.vehicle?.description}</Text>
                      </View>
                    </View>
                  </View>
                </View>
                :
                loadingWorker
                ?
                <ActivityIndicator size="large" color="#3399FF"/>
                :
                <View>
                  <Text style={styles.centeredText}>No driver assigned yet</Text>
                </View>
              }
              <Button onPress={closeModal} title='Close'></Button>
            </ScrollView>
          </Modal>
        </GestureRecognizer>
      )
    }
  }

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Image
          style={styles.image}
          source={require('../../assets/heading.png')}
        />
        <View style={{height: '100%'}}>
          <Menu>
            <MenuTrigger>
              <Text style={{color: 'white'}}>Menu</Text>
            </MenuTrigger>
            <MenuOptions>
              <MenuOption onSelect={logOut} text='Logout' />
              {
                mode === ScannerModeEnum.MERCHANT
                ?
                <MenuOption onSelect={() => {
                  Alert.alert(
                    "Send Pickup Confirmation",
                    "Are you sure you would like to send confirmation email?",
                    [
                      {
                        text: "Cancel",
                        style: "cancel"
                      },
                      { text: "OK", onPress: sendPickingUpEmail }
                    ]
                  );
                }} text='Confirm Pickup' disabled={sendingEmail} />
                :
                null
              }
            </MenuOptions>
          </Menu>
        </View>
      </View>
      {loadingApp ? (
        <View style={styles.loadingContainer}>
          <ActivityIndicator size="large" color="#3399FF" />
        </View>
      ) : renderMode()}
      <Toast ref={(ref) => Toast.setRef(ref)} />
      <View style={styles.scannerView}>
        {
          scannerOn && !orderModalVisible
          ?
          <QRScanner onBarCodeScanned={handleBarCodeScanned} scanning={scanning} />
          :
          <View style={styles.camera}>
            <Image
              style={styles.camera}
              source={require('../../assets/cameraIcon.png')}
            />
            <Text>Tap "Start Scanner" Below</Text>
          </View>
        }
      </View>
      <Button title={scannerOn ? "Stop Scanner" : "Start Scanner"} onPress={scannerOn ? cancelScan : startScan} color={scannerOn ? "gray" : "orange"} />
      {
        mode === ScannerModeEnum.DRIVER && orderCount
        ?
        <Text>{`Scanned: ${orderCount}`}</Text>
        :
        null
      }
      <View style={styles.modalView}>
        {renderSelectWarehouse()}
        {renderReceivedView()}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
    paddingTop: Platform.OS === 'android' ? StatusBar.currentHeight : 0,
    backgroundColor: 'white',
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'black',
    paddingTop: 40,
    height: 80,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
    justifyContent: 'space-between',
    width: '100%',
  },
  modeView: {
    alignItems: 'center',
    marginTop: 5,
    backgroundColor: 'white',
  },
  mainTitle: {
    fontWeight: 'bold',
    fontSize: 20,
    textAlign: 'center',
    margin: 5,
    color: 'black'
  },
  modeSwitcher: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  modeText: {
    textAlign: 'center', 
    color: 'black',
    fontSize: 14,
    fontWeight: 'bold',
    padding: 15,
  },
  modalHeader: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 35,
    justifyContent: 'space-between',
  },
  data: {
    padding: 10
  },
  col: {
    textAlign: 'center'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  redMissingSize: {
    borderWidth: 2,
    padding: 5
  },
  text: {
    fontSize: 12,
    marginBottom: 5
  },
  important: {
    color: 'blue', 
    fontSize: 20, 
    fontWeight: 'bold',
    textAlign: 'center'
  },
  driverNotAssigned: {
    fontWeight: 'bold',
    fontSize: 20,
    textAlign: 'center',
    color: 'red',
    marginBottom: '20%'
  },
  scannerView: {
    justifyContent: 'center',
    alignItems: 'center',
    height: Dimensions.get('window').height / 1.5,
  },
  scanner: {
    height: Dimensions.get('window').height / 1.5,
    width: '100%',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 12,
  },
  status: {
    fontWeight: 'bold',
    fontSize: 18,
    textAlign: 'center',
    margin: 2,
    color: 'black'
  },
  orderId: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
  centeredText: {
    paddingTop: 10,
    textAlign: 'center',
  },
  modalView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    margin: 22,
  },
  orderInfo: {
    margin: 25,
    borderWidth: 2,
    borderRadius: 50,
    minHeight: 400,
    maxHeight: 400,
  },
  driverInfo: {
    margin: 25,
    borderWidth: 2,
    borderRadius: 50,
  },
  picker: {
    height: 45,
    width: 100,
    // color: 'white',
  },
  button: {
    width: 200
  },
  image: {
    width: 120,
    height: 35
  },
  scanButtonContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    width: `100%`,
  },
  scanText: {
    fontSize: 18,
    fontWeight: 'bold',
    color: 'white',
  },
  camera: {
    alignItems: 'center',
    display: 'flex',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: 8,
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    height: Dimensions.get('window').height * 0.35 - 80,
    padding: 30,
  },
  transparentView: {
    width: '100%',
    backgroundColor: `black`,
    opacity: 0.8,
    position: 'absolute',
    zIndex: 30,
    top: Platform.OS === 'android' ? (StatusBar.currentHeight || 0) + 80 : 80,
    padding: 15,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  whiteText: {
    color: 'white',
  },
  bold: {
    fontWeight: 'bold',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  }
});
