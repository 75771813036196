// local
// export const FUNCTIONS_URL = "https://43ad2a5f8026.ngrok.io/shippie-prod/us-central1";
// export const SHIPPIE_URL = 'https://localhost:3000';

// dev
// export const FUNCTIONS_URL = "https://us-central1-shippie-dev.cloudfunctions.net";
// export const SHIPPIE_URL = 'https://shippie-dev.web.app'

// prod
export const FUNCTIONS_URL = "https://us-central1-shippie-prod.cloudfunctions.net";
export const SHIPPIE_URL = 'https://app.shippie.ca'
