import * as React from 'react';
import {
  Text,
  View,
  TextInput,
  Button,
  StyleSheet,
  Platform,
  StatusBar,
  Image,
  Alert,
  ActivityIndicator,
  AppState,
} from 'react-native';
import { FirebaseRecaptchaVerifierModal, FirebaseRecaptchaBanner } from 'expo-firebase-recaptcha';
import { MenuProvider } from 'react-native-popup-menu';
import * as Updates from 'expo-updates';

import { checkNumberWhitelisted } from './src/api/cloudfunctions/authentication';
import {firebaseApp} from './firebase/config';
import Scanner from './src/components/Scanner';
import { deleteLocalStorage, setLocalStorage } from './src/common/localStorage';


export default function App() {
  const recaptchaVerifier = React.useRef(null);
  const [phoneNumber, setPhoneNumber] = React.useState();
  const [verificationId, setVerificationId] = React.useState();
  const [verificationCode, setVerificationCode] = React.useState();
  const [loading, setLoading] = React.useState(false);

  const firebaseConfig = firebaseApp.apps?.length ? firebaseApp.app().options : undefined;
  const attemptInvisibleVerification = true;

  const [loggedIn, setLoggedIn]  = React.useState(false);
  const [initializing, setInitializing] = React.useState(true);

  const [_checking_update, _setCheckingUpdate] = React.useState(false);
  const [showReloadDialog, setShowReloadDialog] = React.useState(false);
  
  
  React.useEffect(() => {
    loadUserData();
  }, [loggedIn]);

  React.useEffect(() => {
    if (Platform.OS !== `web`) {
      AppState.addEventListener('change', _handleAppStateChange);
      Updates.addListener(event => {
        if (event.type === Updates.UpdateEventType.UPDATE_AVAILABLE && !showReloadDialog) {
          Alert.alert(
            'Update',
            'New application update were found, restart must required.',
            [
              {
                text: 'Accept',
                onPress: () => {
                  Updates.reloadAsync();
                }
              },
            ],
            { cancelable: false }
          );
          setShowReloadDialog(true);
        }
      });
      // fresh start check
      _checkUpdates();
    }
  }, []);

  const _handleAppStateChange = (nextAppState: any) => {
    if (nextAppState === 'active') {
      _checkUpdates();
    }
  }
  const _checkUpdates = async () => {
    if (_checking_update !== true) {
      console.log('Checking for an update...');
      _setCheckingUpdate(true);
      try {
        const update = await Updates.checkForUpdateAsync();
        if (update.isAvailable) {
          setShowReloadDialog(true);
          console.log('An update was found, downloading...');
          await Updates.fetchUpdateAsync();
          Alert.alert('', 'New updates', [
            {
              text: 'Accept',
              onPress: () => {
                Updates.reloadAsync();
              }
            },
          ],)
        } else {
          console.log('No updates were found');
        }
      } catch (e) {
        console.log('Error while trying to check for updates', e);
      }
    } else {
      console.log('Currently checking for an update');
    }
  }


  const loadUserData = () => {
    firebaseApp.auth().onAuthStateChanged(async user => {
      if (user) {
        setLocalStorage("authenticated", 'true');

        try {
          const idToken = await firebaseApp.auth().currentUser?.getIdToken(/* forceRefresh */ true);
          if (idToken){
            await setLocalStorage("token", idToken);
            setLoggedIn(true);
          }
        } catch (err) {
          Alert.alert('', err.message);
        } finally {
          setInitializing(false);
        }
      } else {
        deleteLocalStorage("authenticated");
        setInitializing(false);
      }
    });
  };


  const sendVerificationCode = async () => {
    // The FirebaseRecaptchaVerifierModal ref implements the
    // FirebaseAuthApplicationVerifier interface and can be
    // passed directly to `verifyPhoneNumber`.
    setLoading(true);
    try {
      await checkNumberWhitelisted(phoneNumber);
      const phoneProvider = new firebaseApp.auth.PhoneAuthProvider();
      const verificationId = await phoneProvider.verifyPhoneNumber(
        phoneNumber,
        recaptchaVerifier.current
      );
      setVerificationId(verificationId);
      Alert.alert('', 'Verification code has been sent to your phone.');
    }
    catch (err) {
      console.log(err)
      Alert.alert('', err);
    }
    finally {
      setLoading(false);
    }
  }

  const confirmVerificationCode = async () => {
    setLoading(true);
    try {
      const credential = firebaseApp.auth.PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );
      await firebaseApp.auth().signInWithCredential(credential);
      loadUserData();
      Alert.alert('', 'Phone authentication successful 👍');
    } 
    catch (err) {
      Alert.alert('', err.message);
    }
    finally {
      setLoading(false);
    }
  }

  const logOut = async () => {
    await firebaseApp.auth().signOut();
    deleteLocalStorage("authenticated");
    deleteLocalStorage("token");

    setLoggedIn(false);
    Alert.alert('', 'Logged out');
  }

  if (loggedIn) {
    return (
      <MenuProvider>
        <Scanner logOut={logOut} />
      </MenuProvider>
    )
  }
  else {
    return (
      <View style={styles.container}>
        <View style={styles.header}>
          <Image
            style={styles.image}
            source={require('./assets/heading.png')}
          />
        </View>
        
        {
          initializing
          ?
          <View style={styles.loadingContainer}>
            <ActivityIndicator size="large" color="#3399FF" />
          </View>
          :
          <View style={styles.login}>
            <FirebaseRecaptchaVerifierModal
              ref={recaptchaVerifier}
              firebaseConfig={firebaseConfig}
              attemptInvisibleVerification={attemptInvisibleVerification}
            />
            <Text style={styles.title}>Scanner Login</Text>
            
            <Text style={styles.text}>Enter phone number</Text>
            <TextInput
              style={styles.inputs}
              placeholder="+1 999 999 9999"
              defaultValue='+1'
              autoFocus={false}
              autoCompleteType="tel"
              keyboardType="phone-pad"
              textContentType="telephoneNumber"
              onChangeText={phoneNumber => setPhoneNumber(phoneNumber)}
            />
            <Button
              title="Send Verification Code"
              disabled={!phoneNumber || loading}
              onPress={sendVerificationCode}
            />
            <Text style={styles.text}>Enter Verification code</Text>
            <TextInput
              style={styles.inputs}
              editable={!!verificationId}
              placeholder="123456"
              onChangeText={setVerificationCode}
            />
            <Button
              title="Confirm Verification Code"
              disabled={!verificationId || loading}
              onPress={confirmVerificationCode}
            />
            
            {attemptInvisibleVerification && <FirebaseRecaptchaBanner />}
          </View>
        }
      </View>
    );
  };
  
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
    paddingTop: Platform.OS === 'android' ? StatusBar.currentHeight : 0,
  },
  title: {
    textAlign: 'center',
    fontSize: 25,
    fontWeight: 'bold'
  },
  login: {
    padding: '10%',
  },
  text: {
    fontSize: 17,
    paddingTop: '10%'
  },
  inputs: {
    marginVertical: 10, 
    fontSize: 17,
    padding: `15px`
  },
  image: {
    width: 120,
    height: 35
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'black',
    paddingTop: '10%',
    paddingBottom: '3%',
    justifyContent: 'space-between',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  }
});
