import React from 'react';
import { Dimensions, Image, Platform, StyleSheet, Text, View } from 'react-native';
import { BarCodeScanner } from 'expo-barcode-scanner';
import { Camera } from 'expo-camera';

const scanBox = require('./assets/scanBox.png');

const { width } = Dimensions.get('window');

export default function QRScanner({onBarCodeScanned, scanning}) {

  if (Platform.OS === `web`) {
    return (
      <Camera
        onBarCodeScanned={scanning ? onBarCodeScanned : undefined}
        barCodeScannerSettings={{ barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr], }}
        style={[StyleSheet.absoluteFill, styles.container]}
      >
        <Image source={scanBox} style={styles.scanBox} />
      </Camera>
    )
  } else {
    return (
      <BarCodeScanner
        onBarCodeScanned={scanning ? onBarCodeScanned : undefined}
        style={[StyleSheet.absoluteFill, styles.container]}
      >
        <Image source={scanBox} style={styles.scanBox} />
      </BarCodeScanner>
    );
  }
}

const opacity = 'rgba(0, 0, 0, .1)';
const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    height: Dimensions.get('window').height * 0.65,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  scanBox: {
    width: 350,
    height: 350,
    opacity: 0.7,
  },
});