import axios from 'axios';
import {FUNCTIONS_URL} from '../API_URLS';

export const checkNumberWhitelisted = async (phoneNumber: string) => {
  try {
    const response = await axios.post(`${FUNCTIONS_URL}/checkNumberWhitelisted`, {phoneNumber});
    return response.data;
  }
  catch (err){
    throw err.response.data;
  }
}
