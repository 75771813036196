
export const PackageSizeEnum = Object.freeze({
  NULL: "",
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  XLARGE: 'xlarge',
});

export const ScannerModeEnum = Object.freeze({
  DRIVER: "driver",
  WAREHOUSE: 'warehouse',
  MERCHANT: 'merchant',
});

export const OrderStatusEnum = Object.freeze({
  PENDING: "pending",
  CREATED: "created",
  PICKUP: "pickup",
  PICKEDUP: "picked up",
  RECEIVED: 'received',
  TRANSIT: 'transit',
  NEXTUP: 'next up',
  DELIVERED: 'delivered',
  FAILED: 'failed',
  CANCELLED: 'cancelled',
  RETRY: 'retry',
  RETURN: 'return',
});


export interface ShippieOrder {
	merchantId: string;
  id: string; // this is our own order ID
  orderId?: number; // this is the id we are provided from ShipStation
  orderNumber?: string; // order # in ShipStation
  orderKey?: any;
  key?: any;
  dspId?: string;

	email: string;
	fullName: string;
	phoneNumber: string;

  origin?: any;
	address1: string;
  address2: string;
  address3?: string;
	addressVerified: boolean;
	city: string;
	postalCode: string;
	province: string;
	country: string;
  destinationNotes?: string;
	latitude?: string;
  longitude?: string;
  oldAddress?: any;
  googleAddress?: any;

	dimensions?: Dimension;
	weight?: Weight;
  packageMultiplier?: number;
	packageSize?: string;
	packageWeight?: string;
	packageNotes?: string;
	signatureRequired?: string;
  photoRequired?: string;
  
  metadata?: any;
  trackingURL?: any;

	status: string;
	created: number;
  updated: number;
  delivered?: number;
  orderDate?: string;
  shipByDate?: string;
  
  events?: any;
  taskId?: any;
  verified?: any;
  messages?: any;
  sentEmails?: any;
  completionDetails?: any;
}

export interface Dimension {
  length: number;
  width: number;
  height: number;
  units: string;
}

export interface Weight {
  value: number;
  units: string;
  WeightUnits?: number;
}