import axios from 'axios';
import {FUNCTIONS_URL} from '../API_URLS';
import { getLocalStorage } from '../../common/localStorage';

export const getMerchantsPickedUpOrders = async (orderId: string) => {
    const token = await getLocalStorage("token");
    const headers = {
        "Authorization": `Bearer ${token}`,
    }

    try {
        const response = await axios.post(`${FUNCTIONS_URL}/getMerchantsPickedUpOrders`, {orderId}, {headers: headers});
        return response.data;
    }
    catch (err){
        throw err.response.data;
    }
}

export const updateOrderStatusByScanner = async (orderId: string, mode: string, warehouse: string, driver: boolean, retry: boolean) => {
    const token = await getLocalStorage("token");
    const headers = {
        "Authorization": `Bearer ${token}`,
    }

    try {
        const response = await axios.post(`${FUNCTIONS_URL}/updateOrderStatusByScanner`, {orderId, driver, mode, warehouse, retry}, {headers: headers});
        return response.data;
    }
    catch (err){
        throw err.response.data;
    }
}

export const getWorkerByDriver = async (orderId: string) => {
    const token = await getLocalStorage("token");
    const headers = {
        "Authorization": `Bearer ${token}`,
    }

    try {
        const response = await axios.post(`${FUNCTIONS_URL}/getWorkerByDriver`, {orderId}, {headers: headers});
        return response.data;
    }
    catch (err){
        throw err.response.data;
    }
}

export const updatePackageSizeByDriver = async (orderId: string, packageSize: string) => {
    const token = await getLocalStorage("token");
    const headers = {
        "Authorization": `Bearer ${token}`,
    }

    try {
        const response = await axios.post(`${FUNCTIONS_URL}/updatePackageSizeByDriver`, {orderId, packageSize}, {headers: headers});
        return response.data;
    }
    catch (err){
        throw err.response.data;
    }
}

export const sendAwaitingPickupConfirmation = async (merchantId: string, mode: string) => {
    const token = await getLocalStorage("token");
    const headers = {
        "Authorization": `Bearer ${token}`,
    }

    try {
        const response = await axios.post(`${FUNCTIONS_URL}/sendAwaitingPickupConfirmation`, {merchantId, mode}, {headers: headers});
        return response.data;
    }
    catch (err){
        throw err.response.data;
    }
}

export const updatePackageMultiplier = async (orderId: string, packageMultiplier: number) => {
    const token = await getLocalStorage("token");
    const headers = {
        "Authorization": `Bearer ${token}`,
    }

    try {
        const response = await axios.post(`${FUNCTIONS_URL}/updatePackageMultiplier`, {orderId, packageMultiplier}, {headers: headers});
        return response.data;
    }
    catch (err){
        throw err.response.data;
    }
}

export const getScannedOrders = async (mode: string, merchantId: string) => {
    const token = await getLocalStorage("token");
    const headers = {
        "Authorization": `Bearer ${token}`,
    }

    try {
        const response = await axios.post(`${FUNCTIONS_URL}/getScannedOrders`, {mode, merchantId}, {headers: headers});
        return response.data;
    }
    catch (err){
        throw err.response.data;
    }
}
